import React from 'react';
import Navbar from '../components/App/Navbar';
import Footer from '../components/App/Footer';
import Layout from '../components/App/Layout';
import ProjectStartArea from '../components/Index/ProjectStartArea';
import PageBanner from '../components/Common/PageBanner';
import OtherServices from '../components/Services/OtherServices';
import campaignEntries from '../assets/images/campaign/campaign-entries.png';
import campaignShare from '../assets/images/campaign/campaign-share.png';
import campaignEntrant from '../assets/images/campaign/campaign-entrant.png';
import campaignCheck from '../assets/images/campaign/campaign-check.png';
import campaignMetaImage from '../assets/images/campaign/campaign-meta-image.jpg'
import CampaignBanner from '../components/Campaign/CampaignBanner';
import SEO from '../components/App/SEO';
import Image from '../components/UiComponent/image'
import Wrapper from './campaign.style'
import {StaticImage} from 'gatsby-plugin-image';

const Home = () => {
  return (
    <Layout>
      <SEO
        title="キャンペーン機能｜応募から抽選まで一括管理できるSNSキャンペーンツール"
        desc="個人から中小企業・大企業まで、LINE・TwitterなどのSNSからレシートやアンケートなどの従来の応募方法まで提供しています。インスタントウィン、抽選、ハッシュタグ機能が充実。"
        banner={campaignMetaImage}
        pathname={'/campaign'}
        type={'Article'}
        shortName="キャンペーン機能"
        createdAt="2021-03-02"
      />
      <Navbar />
      <PageBanner
          pageTitle="キャンペーン"
          homePageText="Home"
          homePageUrl="/"
          activePageText="キャンペーン"
      />
      <Wrapper>
      <CampaignBanner />
      <div className="container">
        <div className="our-mission-area pb-100">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="our-mission-content">
                  <div className="content">
                    <h2>60以上の応募方法</h2>
                    <p>Twitter、Facebook、LINEといった主要なSNSはもちろん、AppleMusicやSpotifyなどたくさんのプラットフォームを連携。従来のクイズや応募コード、メルマガ登録などと組み合わせることができます。</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="our-mission-image">
                  <StaticImage src="../assets/images/campaign/campaign-entries.png" alt="キャンペーンの応募方法" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-area pb-100">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-img">
                  <StaticImage src="../assets/images/campaign/campaign-share.png" alt="キャンペーンのシェア" />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="about-content">
                  <div className="content">
                    <h2>バイラルシェア</h2>
                    <p>ホームページに集客力がなくても大丈夫。開催したキャンペーンをシェアすることを応募条件にできるため、応募者がシェアしお客様のキャンペーンが広がっていきます。応募フォームもどこにでも設置できるため、ブロガーやインフルエンサーのプラットフォームでキャンペーンを開催できます。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-mission-area pb-100">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="our-mission-content">
                  <div className="content">
                    <h2>応募者情報のカスタマイズ</h2>
                    <p>応募時に必要な住所や電話番号、その他にもチェックボックスでの同意やアンケートのための選択式の質問といった多くの入力項目に対応できます。</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="our-mission-image">
                    <StaticImage src="../assets/images/campaign/campaign-entrant.png" alt="キャンペーン応募者情報" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-area pb-100">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-img">
                  <StaticImage src="../assets/images/campaign/campaign-check.png" alt="キャンペーンの不正応募" />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="about-content">
                  <div className="content">
                    <h2>自動で応募条件をチェック</h2>
                    <p>各サービスのAPIを使用して、応募条件を満たしているか自動でチェックします（許可された機能のみ）。こうすることで応募者は応募への入力が減り、キャンペーンのコンバージョンの増加につながります。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OtherServices/>
      </Wrapper>
      <ProjectStartArea />
      <Footer />
    </Layout>
  )
};

export default Home