import styled from 'styled-components';
const CampaignStyle = styled.div` 
    .container {
      text-align: center;
      margin: 0 auto;
      padding-top: 100px;
      padding-bottom: 10px;
    }
    img.banner {
      margin: 0 auto;
    }
     
    @media only screen and (min-width: 767px) and (max-width: 1356px)  {
      .container {
          padding-top: 90px;
        }

    }
    @media only screen and (max-width: 1199px) and (min-width: 992px){
        .container {
          padding-top: 80px;
        }
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      .container {
          padding-top: 70px;
        }
    }
   
    @media only screen and (max-width: 767px)  and (min-width: 420px)  {
      .container {
        padding-top: 60px;
      }
    }
   
    @media only screen and (max-width: 420px) {
    
      .container {
        padding-top: 50px;
      }
    }
   
`;

export default CampaignStyle