import React from 'react';
import serviceShape4 from '../../assets/images/services/service-shape4.png';
import iconCustomDesign from '../../assets/images/services/icon-custom-design.png';
import iconResponsive from '../../assets/images/services/icon-responsive.png';
import iconImport from '../../assets/images/services/icon-import.png';
import iconFalseEntry from '../../assets/images/services/icon-false-entry.png';
import iconAutoDrawing from '../../assets/images/services/icon-auto-drawing.png';
import iconPromotion from '../../assets/images/services/icon-promotion.png';
import Image from '../../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>その他の機能</h2>
                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>*/}
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <div className="image">
                                <StaticImage src="../../assets/images/services/icon-promotion.png" alt="services" style={{overflow:'initial'}} />
                                </div>
                            </div>
                            <h3>
                                応募してほしい行動を促進
                            </h3>
                            <p>応募ごとに応募点数を設定できるので、重要な応募は応募点数を高くして応募数を増やすことができます。</p>
                            {/*<Link to="/service-details" className="learn-more-btn">*/}
                            {/*    <i className="flaticon-right"></i>*/}
                            {/*    Learn More*/}
                            {/*</Link>*/}
                            <div className="shape">
                                <StaticImage src="../../assets/images/services/service-shape4.png" alt="services" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <div className="image">
                                <StaticImage src="../../assets/images/services/icon-responsive.png" alt="services"  style={{overflow:'initial'}} />
                                </div>
                            </div>
                            <h3>
                                レスポンシブ対応
                            </h3>
                            <p>PC、スマートフォン、iPad全ての端末に対応しております。</p>
                            <div className="shape">
                                <StaticImage src="../../assets/images/services/service-shape4.png" alt="services" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <div className="image">
                                <StaticImage src="../../assets/images/services/icon-custom-design.png" alt="services"  style={{overflow:'initial'}} />
                                </div>
                            </div>
                            <h3>カスタムデザイン</h3>
                            <p>応募フォームの表示項目の変更、アイコンのカラーをブランドカラーに統一したり、タイトルなど自由に変更できます。</p>
                            <div className="shape">
                                <StaticImage src="../../assets/images/services/service-shape4.png" alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item ">
                            <div className="icon">
                                <div className="image">
                                <StaticImage src="../../assets/images/services/icon-import.png" alt="services" style={{overflow:'initial'}} />
                                </div>
                            </div>
                            <h3>
                                    どこでも応募が可能
                            </h3>
                            <p>ホームページ、ECサイト、ブログ、HTMLであればどこにでも応募フォームを設置できます。</p>
                            <div className="shape">
                                <StaticImage src="../../assets/images/services/service-shape4.png" alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <div className="image">
                                <StaticImage src="../../assets/images/services/icon-false-entry.png" alt="services" style={{overflow:'initial'}} />
                                </div>
                            </div>
                            <h3>
                                    不正応募の自動検知
                            </h3>
                            <p>不正なアカウントからの応募、人工的な応募に関して自動で検知し応募を無効とします。</p>
                            <div className="shape">
                                <StaticImage src="../../assets/images/services/service-shape4.png" alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <div className="image">
                                <StaticImage src={"../../assets/images/services/icon-auto-drawing.png"} alt="services" style={{overflow:'initial'}} />
                                </div>
                            </div>
                            <h3>
                                    当選者を自動抽選
                            </h3>
                            <p>応募点数をもとに自動抽選し、公平な抽選を行うことができます。</p>
                            <div className="shape">
                                <StaticImage src="../../assets/images/services/service-shape4.png" alt="services" />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
};

export default ServicesOne