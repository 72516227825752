import React from 'react'
import campaignBanner from '../../assets/images/campaign/campaign-banner.png'
import WrapperStyle from './Campaign.style'
import Image from '../../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';

const CampaignBanner = () => {
    return (
        <WrapperStyle>
        <section className="solutions-area">
            <div className="container" >
              <StaticImage src="../../assets/images/campaign/campaign-banner.png" className="banner" alt="about" width={700}  />
            </div>
        </section>
        </WrapperStyle>
    )
};

export default CampaignBanner;