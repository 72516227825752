import styled from 'styled-components';
export default  styled.div`
  .single-services-box-item .icon .image {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 30%;
    margin: auto;
}
`;

