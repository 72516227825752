import React from 'react'
import {Link} from 'gatsby'
import projectStart from '../../assets/images/project-start1.png'
import shape from '../../assets/images/shape/circle-shape1.png'
import Image from '../../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';

const ProjectStartArea = () => {
    return (
        <div className="project-start-area bg-color ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            {/*<div style={{width: '585px', margin:'auto'}}>*/}
                            <StaticImage src="../../assets/images/project-start1.png" alt="project" />
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>リタッチでキャンペーンを試してみませんか？</h2>
                            <p>アカウント登録は無料ですぐにお試しいただけます。クレジットカードの登録も不要です。</p>

                            {/*<Link href="/signup" className="default-btn">*/}
                            {/*    <i className="flaticon-right"></i>*/}
                            {/*    今すぐ無料で試す*/}
                            {/*    <span></span>*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <StaticImage src="../../assets/images/shape/circle-shape1.png" alt="project" />
            </div>
        </div>
    )
};

export default ProjectStartArea;